import { i18n } from '@/utils/i18n';
import { deepFreeze } from '@/utils/object';

export const PNS = deepFreeze({ REGEX: new RegExp(import.meta.env.VITE_PNS_URL as string, '') });

export enum LS {
  TOKEN = 'opaleApiToken',
  REDIRECT_AFTER_LOGIN = 'redirectAfterLogin',
  PREFILL_INFOS = 'prefillInfos'
}

export enum ROLES {
  ANON = 'anon',
  USER = 'user'
}

export const ACCEPTED_MIMETYPES = deepFreeze({
  pdf: ['application/pdf'],
  doc: ['application/msword'],
  dot: ['application/msword'],
  docx: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  bmp: ['image/bmp'],
  dib: ['image/bmp'],
  gif: ['image/gif'],
  jpe: ['image/jpeg'],
  jpeg: ['image/jpeg'],
  jpg: ['image/jpeg'],
  png: ['image/png'],
  svg: ['image/svg+xml'],
  svgz: ['image/svg+xml'],
  tif: ['image/tiff'],
  tiff: ['image/tiff'],
  webp: ['image/webp'],
  odp: ['application/vnd.oasis.opendocument.presentation'],
  ods: ['application/vnd.oasis.opendocument.spreadsheet'],
  pptx: ['application/vnd.openxmlformats-officedocument.presentationml.presentation'],
  xlsx: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  log: ['text/plain'],
  text: ['text/plain'],
  txt: ['text/plain'],
  odt: ['application/vnd.oasis.opendocument.text'],
  ppt: ['application/vnd.ms-powerpoint'],
  pps: ['application/vnd.ms-powerpoint'],
  pot: ['application/vnd.ms-powerpoint'],
  ppz: ['application/vnd.ms-powerpoint'],
  rtf: ['application/rtf'],
  xls: ['application/vnd.ms-excel'],
  xla: ['application/vnd.ms-excel'],
  xlc: ['application/vnd.ms-excel'],
  xlt: ['application/vnd.ms-excel'],
  xlw: ['application/vnd.ms-excel'],
  xll: ['application/vnd.ms-excel'],
  xld: ['application/vnd.ms-excel'],
  csv: ['text/csv', 'text/plain']
});

export enum NOTIFICATION_EVENT_TYPES {
  MESSAGE = 'message',
  CHILDREN_OPERATION_SUCCESS = 'successChildOperations',
  CHILDREN_OPERATION_ERROR = 'errorsChildOperations'
}

export enum API {
  DATE_FORMAT = 'YYYY-MM-DD'
}

// ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
// EVENTS
export enum ASSIGNMENT_EVENTS {
  UPDATE = 'assignment::update',
  CONFIRM = 'assignment::confirm'
}
export enum ASSISTANT_EVENTS {
  GO_STEP = 'assistant::go-step',
  SUBMIT = 'assistant::submit'
}
export enum BTN_CONFIRM_EVENTS {
  TOGGLE = 'btn-confirm::toggle'
}
export enum BUDGET_IMPUTATIONS_EVENTS {
  UPDATE = 'budget-imputations::update',
  RELOAD = 'budget-imputations::reload'
}
export enum TIMETABLE_TECHNICAL_EVENTS {
  UPDATE = 'timetable-technical::update',
  SCROLL_TO = 'timetable-technical:scroll-to'
}
export enum DEADLINE_DOCUMENTS_TYPES {
  FINANCIAL = 'financial',
  TECHNICAL = 'technical',
  OTHER = 'other',
  PRE_VALIDATION_DOCUMENT = 'pre-validation-document'
}
export enum DOCUMENTS_EVENTS {
  UPDATE = 'document-list:update',
  ADD = 'document-list:add',
  DELETE = 'document-list:delete'
}
export enum EXPENSES_EXTENDED_EVENTS {
  UPDATE = 'expenses-extented::update',
  UPDATE_COMMENT = 'expenses-extented::update-comment'
}
export enum FOG_OVERLAY_EVENTS {
  CLICK = 'fog-overlay::click'
}
export enum FORM_WRAPPER_EVENTS {
  CLEAR_ERRORS = 'form-wrapper::clear-errors',
  SUBMIT = 'form-wrapper::submit',
  UPDATE = 'form-wrapper::update'
}
export enum GEO_EVENTS {
  UPDATE = 'geo::update'
}
export enum GEO_INFOS_EVENTS {
  UPDATE = 'geo-edit:update'
}
export enum GETTER_WRAPPER_EVENTS {
  LOAD = 'getter-wrapper::load',
  UPDATE = 'getter-wrapper::update'
}
export enum HTTP_EVENTS {
  METHOD_SUCCESS = 'http-events::method-success'
}
export enum KITKAT_EVENTS {
  ADD = 'kitkat-events::add'
}
export enum MENU_EVENTS {
  SCROLL_TO = 'menu::scroll-to'
}
export enum NOTIFICATIONS_EVENTS {
  ADD = 'notification-events::add',
  CLEAR = 'notification-events::clear'
}
export enum PAGINATOR_EVENTS {
  SEARCH = 'paginator::search',
  CANCEL_PREVIOUS_REQUEST = 'paginator::cancel-previous-request'
}
export enum TECHNICAL_INFOS_EVENTS {
  UPDATE = 'infos:update'
}
export enum THIRD_PARTY_EVENTS {
  UPDATE = 'third-party:update',
  LIST_UPDATE = 'third-party-list:update',
  CONTACTS_UPDATE = 'third-party_contacts:update'
}
export enum TIMETABLE_EVENTS {
  UPDATE = 'timetable:update',
  SCROLL_TO = 'timetable:scroll-to'
}
export enum TIP_TAUPE_EVENTS {
  HIDE = 'tip-taupe::hide'
}
// ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
// TYPES
export enum BTN_MULTI_STATES {
  IDLE = 'idle',
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success',
  CONFIRM = 'confirm'
}
export enum DATA_ACCESS {
  FINANCIAL_DATA = 'financial_data',
  FINANCIAL_DEALINES = 'financial_deadlines'
}
export enum FAST_BLOCKS {
  DEPENSE_MODALITE = 'depenseModalite',
  PLAN_FINANCEMENT = 'planFinancement',
  NATURE_LISA = 'natureLISA',
  ECHEANCE_FIN = 'echeanceFin',
  ECHEANCE_TECH = 'echeanceTech'
}
export enum FORM_WRAPPER_EVENTS_TYPES {
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
  INVALID = 'invalid',
  SUBMITTED = 'submitted',
  CANCEL = 'cancel'
}
export enum GETTER_WRAPPER_LOAD_TYPES {
  FULL = 'full',
  PARTIAL = 'partial',
  HIDDEN = 'hidden'
}
export enum GETTER_WRAPPER_EVENTS_TYPES {
  SUCCESS = 'success',
  DATA = 'data',
  ERROR = 'error',
  LOADING = 'loading'
}
export enum PROJECT_LIST_DISPLAY_MODE {
  THUMBNAIL = 'thumbnail',
  TABLE = 'table'
}
export enum SCROLL_WATCHER {
  REACH_BOTTOM = 'scroll-watcher::reach-bottom'
}
export enum SUPPORTING_TYPES {
  TECHNICAL = 'technical',
  STEP = 'key_step',
  MILESTONE = 'milestone'
}

export enum USER_ROLE {
  OPALE_ADMINISTRATOR = 'ROLE_OPALE_ADMINISTRATOR',
  SUPERVISOR = 'ROLE_SUPERVISOR',
  EXPERT = 'ROLE_EXPERT',
  USER = 'ROLE_USER',
  CDP_MANAGER = 'ROLE_CDP_MANAGER',
  ACCOUNTING_OFFICER = 'ROLE_ACCOUNTING_OFFICER',
  AUTHORISING_OFFICER = 'ROLE_AUTHORISING_OFFICER',
  RAJ = 'ROLE_RAJ',
  LISA_REFERENCIAL_ADMINISTRATOR = 'ROLE_LISA_REFERENCIAL_ADMINISTRATOR',
  DAF_DAJ = 'ROLE_DAF_DAJ',
  COMITOLOGY_SECRETARY = 'ROLE_COMITOLOGY_SECRETARY',
  THIRD_PARTY_WITHOUT_SIRET_MANAGEMENT = 'ROLE_THIRD_PARTY_WITHOUT_SIRET_MANAGEMENT',
  THIRD_PARTY_VALIDATOR = 'ROLE_THIRD_PARTY_VALIDATOR',
  POLE_COORDINATOR = 'ROLE_POLE_COORDINATOR',
  EXTERNAL_AUDITOR = 'ROLE_EXTERNAL_AUDITOR',
  BUDGET_PILOT = 'ROLE_BUDGET_PILOT',
  MANAGER = 'ROLE_MANAGER',
  CONSULTATION = 'ROLE_CONSULTATION',
  ADEME_COMMON = 'ROLE_ADEME_COMMON',
  INSTRUCTOR = 'ROLE_INSTRUCTOR',
  __CONNECTED__ = '__CONNECTED__',
  __FAKE_ACCOUNTING__ = '__FAKE_ACCOUNTING__'
}

// ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
// VALUES
export const ID_GEO_ZONE_FRANCE = '075530d1-8660-4a2f-bd49-8beeddd49803';
export enum CONTACT_ROLE_ID {
  LEGAL_REPRESENTATIVE_ROLE_ID = '8ce1c936-26e8-4589-b561-ea0111dac16e',
  ADMINISTRATIVE_CONTACT_ROLE_ID = '88d8bb6b-b649-4443-b16e-203edca68d5c',
  TECHNICAL_CONTACT_ROLE_ID = 'd0f9c3fb-a62d-44bb-bd93-8849f03aedb6',
  DELEGATE_SIGNATURE_ROLE_ID = 'efb896de-340d-4379-9fe5-34b4075d6017'
}

// ▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀▀
// REFERENTIALS
export const REFERENTIALS = deepFreeze({
  ASSIGNMENT_ANSWERS: [
    { id: 'accepted', value: i18n.t('assignment.accept') },
    { id: 'refused', value: i18n.t('assignment.refuse') }
  ] as const,
  ASSIGNMENT_MOTIVATIONS: [
    { id: 'conflict_of_interest', value: i18n.t('assignment.motivation.conflict') },
    { id: 'non_competent', value: i18n.t('assignment.motivation.domain') },
    { id: 'lack_of_time', value: i18n.t('assignment.motivation.time') },
    { id: 'other', value: i18n.t('assignment.motivation.other') }
  ] as const,
  ASSIGNMENT_NOTES: [
    { id: 'favorable', value: i18n.t('assignment.opinion.favorable') },
    { id: 'reserved', value: i18n.t('assignment.opinion.reserved') },
    { id: 'unfavorable', value: i18n.t('assignment.opinion.unfavorable') }
  ] as const,
  DEADLINE_TYPES: [
    { id: 'advance', value: i18n.t('projectSummary.timetable.typeList.advance') },
    { id: 'advance_notification', value: i18n.t('projectSummary.timetable.typeList.advance_notification') },
    { id: 'intermediate', value: i18n.t('projectSummary.timetable.typeList.intermediate') },
    { id: 'balance', value: i18n.t('projectSummary.timetable.typeList.balance') }
  ] as const,
  DOCUMENTS_TYPES: [
    { id: 'dépôt de dossier', value: 'Dépôt de dossier' },
    { id: 'instruction', value: 'Instruction' },
    { id: 'juridique', value: 'Juridique' },
    { id: 'justificatifs', value: 'Justificatifs' },
    { id: 'correspondance', value: 'Correspondance' },
    { id: 'valorisation - communication', value: 'Valorisation - communication' },
    { id: 'autres', value: 'Autres' }
  ] as const,
  FINANCING_AIDS_TYPES: [
    { id: 'refundable_aid', value: i18n.t('financial.plan.aidsType.refundable_aid') },
    { id: 'subsidy', value: i18n.t('financial.plan.aidsType.subsidy') },
    { id: 'refundable_aid_subsidy', value: i18n.t('financial.plan.aidsType.refundable_aid_subsidy') }
  ] as const,
  FINANCING_STATUS_TYPES: [
    { id: 'earned', value: i18n.t('financial.plan.statusType.earned') },
    { id: 'requested', value: i18n.t('financial.plan.statusType.requested') },
    { id: 'expected', value: i18n.t('financial.plan.statusType.expected') }
  ] as const,
  GENDERS: [
    { id: 'M.', value: i18n.t('contact.m') },
    { id: 'Mme', value: i18n.t('contact.mrs') }
  ] as const,
  GEO_ZONE_PURPOSES: [
    { id: 'project_location', value: i18n.t('technical.geoZonePurpose.project_location') },
    { id: 'rd_location', value: i18n.t('technical.geoZonePurpose.rd_location') },
    { id: 'demonstration_location', value: i18n.t('technical.geoZonePurpose.demonstration_location') },
    { id: 'production_location', value: i18n.t('technical.geoZonePurpose.production_location') },
    { id: 'site_to_visit', value: i18n.t('technical.geoZonePurpose.site_to_visit') }
  ] as const,
  HTTP_METHODS: ['DELETE', 'GET', 'PATCH', 'POST', 'PUT'] as const,
  PRIVACY_REASONS: [
    { id: 'business_secrecy', value: i18n.t('privacy.privacyReasons.businessSecrecy') },
    { id: 'statistics_secrecy', value: i18n.t('privacy.privacyReasons.statisticsSecrecy') },
    { id: 'bank_secrecy', value: i18n.t('privacy.privacyReasons.bankSecrecy') },
    { id: 'personal_data', value: i18n.t('privacy.privacyReasons.personalData') },
    { id: 'other', value: i18n.t('privacy.privacyReasons.other') }
  ] as const,
  PROJECT_HEALTH_STATUS: [
    { id: 'none', value: 'Aucun' },
    { id: 'good', value: 'OK' },
    { id: 'too_slow', value: 'Trop lent' },
    { id: 'blocked', value: 'Bloqué' },
    { id: 'unknown', value: 'Inconnu' },
    { id: 'to_abandon', value: 'À abandonner' }
  ] as const,
  YESNO: [
    { id: true, value: i18n.t('yes') },
    { id: false, value: i18n.t('no') }
  ] as const
});
